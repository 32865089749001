.loader-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.loader-container.hidden {
	display: none;
}
.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	font-family: 'Oxygen', sans-serif;
	font-size: 14px;
	background: #fff;
	width: 100px;
	height: 140px;
	margin: -70px 0 0 -50px;
}
.loading {
	color: #1c174c;
	font-weight: bold;
}

.wait {
	color: #d1cbd9;
	font-weight: 300;
}

.loader {
	margin: 3px auto 15px;
	border: 10px solid #f3f3f3; /* Light grey */
	border-top-color: #211651; /* Blue */
	border-bottom-color: #a399b6;
	border-right-color: #776a93;
	border-left-color: #d1cbd9;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 1.4s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
