body {
  background: #f8f8f8;
  padding: 20px;
  margin: 0;
}
.container-fluid {
  position: relative;
  width: 100%;
  max-width: 600px;
  padding: 20px 20px 10px;
  background: #fff;
  margin: 0 auto;
  border-radius: 0px;
  border: 1px solid #f5f5f5;
}
.generator{
  font-size: 10pt;
}

label {
  font-weight: 500;
  font-size: 1.5rem;
}

button {
  margin-top: 10px;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  font-weight: 400;
  transition: all .25s linear;
}

.alert.warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert.danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert.hide {
  display: none;
}

.copyright {
  padding-top: 10px;
}

#support-mail:hover {
  text-decoration: underline;
}
